<template>
  <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      keepAliveList: ["original", "current", "draw"]
    }
  },
  created() {
    if (navigator.userAgent.indexOf("MicroMessenger") !== -1) {
      // 是微信内置浏览器
      ElMessage({ message: '当前使用的微信内置浏览器，下载功能受影响，请使用外部浏览器', duration: 3000, danger: 'error' })
    }
  }
}
</script>

<style>
</style>
