import 'intersection-observer'; // 引入 polyfill

const LazyLoad = {
  install(Vue) {
    Vue.directive('lazy', {
      inserted: (el, binding) => {
        function loadImage() {
          const imageElement = Array.from(el.children).find(
            (el) => el.nodeName === 'IMG'
          );
          if (imageElement) {
            imageElement.src = binding.value;
          }
        }

        function createObserver() {
          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                loadImage();
                observer.unobserve(el);
              }
            });
          });
          observer.observe(el);
        }

        if (!window.IntersectionObserver) {
          loadImage(); // 浏览器不支持 IntersectionObserver 时，直接加载图片
        } else {
          createObserver();
        }
      },
    });
  },
};

export default LazyLoad;