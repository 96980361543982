import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '../store'
import { getToken, removeToken } from '@/utils/auth'
import {BASE_URL} from '@/utils/common.js' 
import router from '../router'

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL, // api的base_url
  timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */
    const res = response.data
    if (res.code !== 200) {
      ElMessage({
        message: res.message,
        type: 'error',
      })
      // 401:未登录;
      if (res.code === 401) {
        ElMessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    if (error.response.data.code === 401 || error.response.data.code === 417) {
      console.log('401')
      removeToken()
      ElMessageBox.confirm('您已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          router.push("/login")
        }).catch(error => {
          console.log(error)
        })
      }).catch(() => {
        store.dispatch('LogOut').then(() => {
          router.push("/home")
        }).catch(error => {
          console.log(error)
        })
      })
    }
    return Promise.reject(error)
  }
)

export default service
