import { createRouter, createWebHistory } from 'vue-router';
import Layout from '../views/layout/Layout'

export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  
  {
    path: '',
    component: Layout,
    redirect: '/home',
    meta: { keepAlive: true },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'home', keepAlive: true }
      },
      {
        path: '/like',
        name: 'like',
        component: () => import('@/views/like/index'),
        meta: { title: '猜您喜欢', icon: 'draw', keepAlive: true }
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/detail/index')
      },
      {
        path: '/original',
        name: 'original',
        component: () => import('@/views/original/index'),
      },
      {
        path: '/newPhoto',
        name: 'newPhoto',
        component: () => import('@/views/newPhoto/index'),
      },
      {
        path: '/fullPhoto',
        name: 'fullPhoto',
        component: () => import('@/views/fullPhoto/index'),
      },
      {
        path: '/newPhoto',
        name: 'newPhoto',
        component: () => import('@/views/newPhoto/index'),
      },
      {
        path: '/fitting',
        name: 'fitting',
        component: () => import('@/views/fitting/index'), 
      },
      {
        path: '/analyse',
        name: 'analyse',
        component: () => import('@/views/analyse/index'),
      },
      { 
        path: '/center', 
        name: 'center',
        component: () => import('@/views/center/index'),
        meta: { title: '个人中心', icon: 'center', keepAlive: true }
      },
    ]
  },
]
// 根据后端menu表记录来匹配相对应的name, title,icon等字段的值,动态路由，需要配置用户权限才能访问
export const asyncRouterMap = [
  { path: '*', redirect: '/404', hidden: true }
]
const router = createRouter({
  history: createWebHistory(),
  routes: constantRouterMap
});
export default router;